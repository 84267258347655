*,
*:after,
*:before {
  box-sizing: border-box;
}

a {
  text-decoration: none;
  // color: $text-color;
  color: inherit;
}

ul {
  list-style: none;
}

sup {
  font-size: smaller;
  line-height: 1;
  color: unset;
}

html {
  font-size: 3.125vw;
  font-family: $primary-font;
  @include mq(max) {
    @media (orientation: landscape) {
      font-size: 3.125vh;
    }
  }
  @include mq(min, $max-width) {
    font-size: $max-width * 0.03125;
  }
}

img {
  max-width: 100%;
  height: auto;
  margin: 0 auto;
  display: block;
  @include mq(max) {
    width: 100%;
  }
}

.cas {
  font-family: $secondry-font;
}

.gen {
  font-family: $tertiary-font;
}

.pla {
  font-family: $fourth-font;
}

.sp {
  @include mq() {
    display: none !important;
  }
}

.pc {
  @include mq(max) {
    display: none !important;
  }
}

body {
  font-size: 16px;
  color: $text-color;
  font-weight: $regular;
  font-feature-settings: "palt";
  background-color: $background-color;
  position: relative;
  overflow: hidden;

  &.loaded {
    overflow: auto;
  }

  &.open {
    overflow: hidden;
  }
}

.obg-c {
  object-fit: cover;
  font-family: 'object-fit: cover;';
}

.obg-con {
  object-fit: contain;
  font-family: 'object-fit: contain;';
}

.container {
  margin: 0 auto;
}

main {
  display: block;
  position: relative;
  z-index: 0;
}
