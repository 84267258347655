@mixin mq($rule: min, $breakpoint: $break-point) {
  @if $rule == min {
    $breakpoint: $breakpoint + 1;
  }
  @media (#{$rule}-width: $breakpoint) {
    @content;
  }
}

@mixin MaxWidth() {
  max-width: $max-width;
  margin: 0 auto;
}
@mixin Container() {
  max-width: $container-width;
  margin: 0 auto;
}

@function cal($size, $device : sp) {
  $return : 0;
  @if $device == pc {
    $ratio : $pcsize / $spsize;
    $return: $size / $spsize * 100 / 3.125 / $ratio;
  }
  @elseif $device == sp {
    $return: $size / $spsize * 100 / 3.125;
  }
  @return $return * 1rem;
}

@function calvw($size, $device : sp) {
  $return : 0;
  @if $device == pc {
    $ratio : $pcsize / $defaultPCsize;
    $return: ($size) / $defaultPCsize * 100 / $ratio;
  }
  @elseif $device == sp {
    $return: $size / $spsize * 100;
  }
  @return $return * 1vw;
}
