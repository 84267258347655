@charset "UTF-8";
/*-----------------------------------

FONT FAMILY

-----------------------------------*/
/*-----------------------------------

FONT WEIGHT

-----------------------------------*/
/*-----------------------------------


  LOADING SCREEN


-----------------------------------*/
@keyframes loading {
  0%,
  100%,
  80% {
    box-shadow: 0 2.5rem 0 -1.3rem; }
  40% {
    box-shadow: 0 2.5rem 0 0; } }

.load_bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #fff;
  z-index: 100000;
  transform: translate3d(0, 0, 0); }
  body.loaded .load_bg {
    visibility: hidden;
    opacity: 0;
    transition: 0.3s ease opacity, 0s visibility 0.3s; }
  .load_bg__contents:after, .load_bg__contents:before, .load_bg__contents {
    border-radius: 50%;
    width: 2.5em;
    height: 2.5em;
    animation-fill-mode: both;
    animation: loading 3s infinite ease-in-out;
    transform: translate3d(0, 0, 0); }
  .load_bg__contents {
    color: #3e3a39;
    font-size: 10px;
    margin: 80px auto;
    position: absolute;
    left: 50%;
    top: calc(50% - 5rem);
    transform: translate3d(-50%, -50%, 0);
    animation-delay: -0.16s; }
  .load_bg__contents:after, .load_bg__contents:before {
    content: '';
    position: absolute;
    top: 0; }
  .load_bg__contents:before {
    left: -3.5rem;
    animation-delay: -0.32s; }
  .load_bg__contents:after {
    left: 3.5rem; }

/*-----------------------------------


  FADE IN


-----------------------------------*/
.js-fadeIn {
  visibility: hidden;
  opacity: 0;
  transform: translateY(50px); }
  .js-fadeIn.fadeIn {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
    transition: 0s visibility, 0.8s opacity ease, 0.8s transform ease; }

/*
html5doctor.com Reset Stylesheet
v1.6.1
Last Updated: 2010-09-17
Author: Richard Clark - http://richclarkdesign.com
Twitter: @rich_clark
*/
html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

body {
  line-height: 1; }

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

nav ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

/* change colours to suit your needs */
ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none; }

/* change colours to suit your needs */
mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold; }

del {
  text-decoration: line-through; }

abbr[title], dfn[title] {
  border-bottom: 1px dotted;
  cursor: help; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

/* change border colour to suit your needs */
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0; }

input, select {
  vertical-align: middle; }

sup {
  vertical-align: super; }

sub {
  vertical-align: sub; }

*,
*:after,
*:before {
  box-sizing: border-box; }

a {
  text-decoration: none;
  color: inherit; }

ul {
  list-style: none; }

sup {
  font-size: smaller;
  line-height: 1;
  color: unset; }

html {
  font-size: 3.125vw;
  font-family: dnp-shuei-mincho-pr6n, sans-serif; }

@media (max-width: 768px) and (orientation: landscape) {
  html {
    font-size: 3.125vh; } }
  @media (min-width: 1281px) {
    html {
      font-size: 40px; } }

img {
  max-width: 100%;
  height: auto;
  margin: 0 auto;
  display: block; }
  @media (max-width: 768px) {
    img {
      width: 100%; } }

.cas {
  font-family: "Castoro", sans-serif; }

.gen {
  font-family: source-han-sans-japanese, sans-serif; }

.pla {
  font-family: "Palatino Linotype", sans-serif; }

@media (min-width: 769px) {
  .sp {
    display: none !important; } }

@media (max-width: 768px) {
  .pc {
    display: none !important; } }

body {
  font-size: 16px;
  color: #3e3a39;
  font-weight: 400;
  font-feature-settings: "palt";
  background-color: #fff;
  position: relative;
  overflow: hidden; }
  body.loaded {
    overflow: auto; }
  body.open {
    overflow: hidden; }

.obg-c {
  object-fit: cover;
  font-family: 'object-fit: cover;'; }

.obg-con {
  object-fit: contain;
  font-family: 'object-fit: contain;'; }

.container {
  margin: 0 auto; }

main {
  display: block;
  position: relative;
  z-index: 0; }

@media (min-width: 769px) {
  header .header {
    width: 100%; }
    header .header__inner {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      width: 100%;
      max-width: 32rem;
      padding: 0.825rem 1.075rem 0.825rem 0.95rem;
      margin: 0 auto; }
    header .header__logo {
      width: 8.625rem;
      margin-right: auto;
      z-index: 2; }
    header .header__copyright {
      width: 3.275rem;
      padding-top: 0.3rem; } }

@media (max-width: 768px) {
  header {
    height: 5.97333rem; }
    header .header {
      width: 100%;
      height: 5.97333rem;
      position: fixed;
      background: #fff;
      z-index: 10000; }
      header .header__inner {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        width: 100%;
        max-width: 32rem;
        padding: 1.536rem 1.10933rem 0.68267rem 1.70667rem;
        margin: 0 auto; }
      header .header__logo {
        width: 15.85323rem;
        margin-right: auto;
        margin-bottom: 0.26453rem; }
      header .header__hamburger {
        width: 1.792rem;
        height: 1.36533rem;
        position: relative;
        cursor: pointer; }
        header .header__hamburger span {
          display: inline-block;
          width: 100%;
          height: 0.17067rem;
          background-color: #646161;
          position: absolute;
          left: 0; }
          header .header__hamburger span:nth-child(1) {
            top: 0; }
          header .header__hamburger span:nth-child(2) {
            top: calc(50% - 0.08533rem); }
          header .header__hamburger span:nth-child(1) {
            top: calc(100% - 0.17067rem); } }

@media (min-width: 769px) {
  footer {
    background-color: #0F2878;
    position: relative; }
    footer .footer {
      color: #fff;
      width: 100%;
      max-width: 32rem;
      margin: 0 auto;
      padding: 3.45rem 1.625rem 0.675rem;
      position: relative; }
      footer .footer__pagetop {
        width: 2.08075rem;
        position: absolute;
        right: 1.65rem;
        top: 0; }
        footer .footer__pagetop img {
          width: 100%; }
      footer .footer__txt {
        margin-bottom: 0.335rem;
        font-size: 0.45rem;
        line-height: 1.44444;
        letter-spacing: 0.1em; }
      footer .footer__list {
        font-size: 0.35rem;
        line-height: 1.78571;
        margin-bottom: 1.6625rem; }
      footer .footer__logo {
        width: 17.3rem;
        margin-bottom: 0.91rem; }
      footer .footer__copyright {
        width: 4.575rem;
        margin-right: auto; } }

@media (max-width: 768px) {
  footer {
    background-color: #0F2878; }
    footer .footer {
      color: #fff;
      width: 100%;
      max-width: 32rem;
      margin: 0 auto;
      padding: 10.41067rem 3.41333rem 1.70667rem;
      position: relative; }
      footer .footer__pagetop {
        width: 5.12rem;
        height: 5.12rem;
        position: absolute;
        top: 0;
        right: 1.70667rem; }
        footer .footer__pagetop img {
          width: 100%;
          height: 100%; }
      footer .footer__txt {
        font-size: 1.19467rem;
        line-height: 1.57143;
        letter-spacing: 0.1em;
        margin-bottom: 1.39947rem; }
      footer .footer__list {
        font-size: 1.024rem;
        line-height: 1.83333;
        letter-spacing: 0.1em;
        margin-bottom: 3.8144rem; }
      footer .footer__logo {
        width: 25.17333rem;
        margin-bottom: 1.54453rem; }
      footer .footer__copyright {
        width: 12.37333rem;
        margin-right: auto; } }

@media (min-width: 769px) {
  aside {
    position: absolute;
    top: 6.575rem;
    left: 0;
    z-index: 100; } }
  @media (min-width: 769px) and (min-width: 1281px) {
    aside {
      left: calc((100vw - 1280px) / 2); } }

@media (min-width: 769px) {
    aside .aside {
      padding-left: 0.75rem; }
      aside .aside__link {
        margin-bottom: 1.3rem; }
        aside .aside__link li {
          padding-bottom: 0.25rem; }
          aside .aside__link li:nth-child(n + 2) {
            margin-top: 0.55rem; }
          aside .aside__link li:nth-child(6) img {
            height: 0.9rem; }
          aside .aside__link li img {
            height: 0.275rem;
            width: auto;
            margin-left: 0; }
      aside .aside__sns {
        width: 0.5rem; } }

@media (max-width: 768px) {
  aside {
    width: 100%;
    height: 100vh;
    background: #ded9c7;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000;
    transform: translateX(100%);
    opacity: 0;
    transition: 0.8s ease transform, 0.8s ease opacity; }
    aside.open {
      transform: translateX(0%);
      opacity: 1; }
    aside .aside {
      width: 100%;
      max-width: 32rem;
      height: 100%;
      padding: 2.56rem 3.584rem;
      margin: 0 auto;
      position: relative;
      overflow: auto; }
      aside .aside__bg {
        width: 8.87467rem;
        height: auto;
        position: absolute;
        top: 2.90133rem;
        left: 0;
        z-index: -1; }
      aside .aside__btn {
        display: inline-block;
        width: 2.816rem;
        height: 2.816rem;
        background-color: #646161;
        border-radius: 50%;
        position: absolute;
        top: 0.85333rem;
        right: 0.85333rem;
        z-index: 2; }
        aside .aside__btn::before {
          content: "";
          display: inline-block;
          width: 1.10933rem;
          height: 2.26133rem;
          background-color: #646161;
          position: absolute;
          top: -0.85333rem;
          left: 0;
          z-index: 0; }
        aside .aside__btn span {
          width: 1.80907rem;
          height: 0.17067rem;
          background-color: #ded9c7;
          position: absolute;
          top: 50%;
          left: 50%; }
          aside .aside__btn span:nth-child(1) {
            transform: translate(-50%, -50%) rotate(45deg); }
          aside .aside__btn span:nth-child(2) {
            transform: translate(-50%, -50%) rotate(-45deg); }
      aside .aside__link {
        padding: 9.64267rem 0 3.584rem;
        margin-bottom: 4.43733rem; }
        aside .aside__link li {
          text-align: right;
          padding-bottom: 1.024rem; }
          aside .aside__link li a {
            display: inline-block; }
          aside .aside__link li:nth-child(n + 2) {
            margin-top: 2.13333rem; }
          aside .aside__link li:nth-child(6) img {
            height: 4.096rem; }
          aside .aside__link li img {
            height: 1.36533rem;
            width: auto;
            margin-right: 0; }
      aside .aside__sns {
        width: 1.70667rem;
        margin-right: 0;
        margin-left: auto;
        margin-bottom: 3.66933rem; }
      aside .aside__copy {
        width: 11.17867rem;
        margin-right: 0;
        margin-left: auto; } }

@media (min-width: 769px) {
  #top .container {
    width: 100%;
    max-width: 32rem;
    position: relative; }
  #top .sec {
    position: relative;
    z-index: 1; }
    #top .sec-title {
      position: relative;
      color: #646161;
      text-align: center;
      height: 1.0125rem;
      margin-bottom: 1.925rem; }
      #top .sec-title h2 {
        display: inline-block;
        height: 100%; }
      #top .sec-title span {
        display: none !important; }
      #top .sec-title img {
        width: auto;
        height: 100%; }
      #top .sec-title::after {
        content: '';
        position: absolute;
        left: 50%;
        bottom: -1.175rem;
        transform: translateX(-50%);
        display: inline-block;
        width: 1.4rem;
        height: 0.125rem;
        background-color: #0F2878; }
    #top .sec-sub {
      font-size: 0.65rem;
      line-height: 1.92308;
      letter-spacing: 0.075em;
      color: #3e3a39;
      text-align: center;
      margin-bottom: 1.5375rem; }
    #top .sec-txt {
      font-size: 0.375rem;
      line-height: 2.66667;
      letter-spacing: 0.1em;
      color: #3e3a39;
      text-align: left;
      width: 100%;
      padding: 0 4.7rem;
      margin: 0 auto; }
  #top .img-parallax {
    width: 100%;
    height: 15.45rem;
    position: relative;
    z-index: 0;
    overflow: hidden; }
    #top .img-parallax.parallax01 img {
      transform: translate(-50%, -80%); }
    #top .img-parallax img {
      width: 100%;
      max-width: none;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -65%);
      z-index: -1; }
    #top .img-parallax + .sec::before {
      content: "";
      display: inline-block;
      width: 27.3rem;
      height: 4rem;
      position: absolute;
      top: -2rem;
      right: 0;
      z-index: 0; }
  #top #kv {
    margin-top: -1.8rem; }
    #top #kv .kv__slider {
      width: 27.3rem;
      height: 15.55rem;
      margin-right: 0;
      margin-left: auto;
      z-index: 1; }
      #top #kv .kv__slider__item {
        width: 100%;
        position: relative; }
        #top #kv .kv__slider__item img {
          width: 100%;
          height: 100%;
          object-fit: cover; }
    #top #kv .kv__info {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 5.55rem;
      height: 1.825rem;
      background-color: #fff;
      position: absolute;
      bottom: 0;
      right: 0;
      z-index: 1; }
      #top #kv .kv__info__num {
        width: auto;
        height: 0.2675rem; }
        #top #kv .kv__info__num img {
          width: auto;
          height: 100%; }
      #top #kv .kv__info__timebar {
        width: 0.85rem;
        height: 1px;
        background-color: #cecdcd;
        margin: 0 0.4rem;
        position: relative; }
        #top #kv .kv__info__timebar i {
          display: inline-block;
          width: 0;
          height: 100%;
          background-color: #0F2878;
          position: absolute;
          top: 0;
          left: 0; }
  #top #concept .container {
    padding: 4.225rem 0 4.525rem 0; }
  #top #business .container {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 50%;
    z-index: 3;
    transform: translateX(-50%); }
  #top #business .business__img {
    width: 100%;
    height: 21.33325rem; }
    #top #business .business__img img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
  #top #business .business__box {
    position: absolute;
    top: 3.19rem;
    left: 4.2rem;
    width: 23.6rem;
    padding: 0.46rem;
    background-color: rgba(255, 255, 255, 0.8); }
    #top #business .business__box__inner {
      border: 2px solid;
      border-color: #0F2878;
      padding-top: 1.725rem;
      padding-bottom: 1.785rem; }
      #top #business .business__box__inner__title {
        position: relative;
        width: 7.15rem;
        margin: 0 auto 1.415rem; }
        #top #business .business__box__inner__title span {
          display: none !important; }
        #top #business .business__box__inner__title::after {
          content: '';
          position: absolute;
          left: 50%;
          top: 1.355rem;
          transform: translateX(-50%);
          display: inline-block;
          width: 1.2375rem;
          height: 3.54px;
          background-color: #0F2878; }
      #top #business .business__box__inner__sub {
        font-size: 0.53025rem;
        letter-spacing: 0.075em;
        color: #646161;
        text-align: center;
        margin: 0 auto 1.225rem; }
        #top #business .business__box__inner__sub br {
          display: none; }
      #top #business .business__box__inner__logo {
        width: 9.198rem;
        margin: 0 auto 0.5625rem; }
      #top #business .business__box__inner__txt {
        width: 8.5rem;
        font-size: 0.35rem;
        line-height: 1.85714;
        letter-spacing: 0.2em;
        color: #0F2878;
        text-align: center;
        margin: 0 auto; }
        #top #business .business__box__inner__txt br {
          display: none; }
      #top #business .business__box__inner__img {
        width: 17.2155rem;
        margin: -1.45rem auto 0; }
  #top #about {
    background-color: #F1EFE8; }
    #top #about::before {
      background-color: #F1EFE8; }
    #top #about .container {
      padding: 2rem 0 2.825rem; }
    #top #about .about__sub {
      margin-bottom: 0.995rem; }
  #top #message {
    background-color: #F1EFE8;
    overflow: hidden; }
    #top #message .container {
      padding: 2.825rem 0 5.75rem; }
    #top #message .message__bg {
      width: 50.875rem;
      height: auto;
      position: absolute;
      bottom: 3.175rem;
      left: calc(50% - 50.875rem);
      transform: translateX(50.875rem);
      z-index: -1; }
    #top #message .message__sub {
      text-align: left;
      width: 100%;
      padding: 0 4.7rem;
      margin: 0 auto 0.92rem; }
    #top #message .message__content {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      padding: 0 4.7rem;
      margin: 0 auto; }
      #top #message .message__content__txt {
        width: 14.9rem;
        font-size: 0.375rem;
        line-height: 2.66667;
        letter-spacing: 0.1em;
        color: #3e3a39;
        margin-right: auto; }
      #top #message .message__content__imges__img {
        width: 5.75rem;
        margin-left: auto; }
      #top #message .message__content__imges__txt {
        font-size: 0.325rem;
        line-height: 1.38462;
        letter-spacing: 0.1em;
        color: #3e3a39;
        text-align: right;
        padding-top: 0.385rem; }
  #top #feature {
    background-color: #fff; }
    #top #feature .container {
      padding: 4.0475rem 0 0 0; }
    #top #feature .feature__sub {
      margin-bottom: 3.165rem; }
    #top #feature .feature__block {
      position: relative;
      height: 15.5rem;
      overflow: hidden; }
      #top #feature .feature__block__doc {
        width: 16rem; }
        #top #feature .feature__block__doc__title {
          width: 3.275rem;
          margin-bottom: 1.6rem; }
        #top #feature .feature__block__doc__box {
          position: relative;
          width: 11.3rem;
          min-height: 12.05rem;
          padding-top: 0.9775rem;
          padding-bottom: 0.725rem;
          background-color: #fff;
          z-index: 2; }
          #top #feature .feature__block__doc__box__sub {
            font-size: 0.6rem;
            letter-spacing: 0.075em;
            color: #3e3a39;
            margin-bottom: 1.08rem; }
          #top #feature .feature__block__doc__box__txt {
            font-size: 0.35rem;
            line-height: 2.42857;
            letter-spacing: 0.075em;
            color: #3e3a39; }
      #top #feature .feature__block__img {
        width: 21.7rem;
        height: 100%;
        position: absolute;
        top: 0;
        z-index: 1;
        overflow: hidden; }
      #top #feature .feature__block.block01, #top #feature .feature__block.block03 {
        padding-top: 2.4rem; }
        #top #feature .feature__block.block01 .feature__block__doc__title, #top #feature .feature__block.block03 .feature__block__doc__title {
          margin-left: 4.725rem;
          margin-right: auto; }
        #top #feature .feature__block.block01 .feature__block__doc__box, #top #feature .feature__block.block03 .feature__block__doc__box {
          padding-right: 1.3rem;
          margin-right: 0;
          margin-left: auto; }
        #top #feature .feature__block.block01 .feature__block__img, #top #feature .feature__block.block03 .feature__block__img {
          right: 0; }
      #top #feature .feature__block.block02 {
        padding-top: 2rem; }
        #top #feature .feature__block.block02 .feature__block__doc {
          margin-right: 0;
          margin-left: auto; }
          #top #feature .feature__block.block02 .feature__block__doc__title {
            margin-right: 4.5rem;
            margin-left: auto; }
          #top #feature .feature__block.block02 .feature__block__doc__box {
            padding-left: 1.3rem;
            padding-bottom: 0rem;
            margin-right: auto;
            margin-left: 0; }
        #top #feature .feature__block.block02 .feature__block__img {
          left: 0; }
  #top #service {
    background-color: #F1EFE8; }
    #top #service .container {
      padding: 5.125rem 0 5.5rem; }
    #top #service .service__txt {
      text-align: center; }
    #top #service .service__list {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-wrap: wrap;
      width: 100%;
      padding: 0 4.05rem;
      margin: 2.5rem auto 0; }
      #top #service .service__list__item {
        width: calc(33.3% - 1.3rem);
        margin: 0 0.65rem; }
        #top #service .service__list__item__img {
          width: 100%;
          margin-bottom: 0.6575rem; }
        #top #service .service__list__item__title {
          font-size: 0.45rem;
          letter-spacing: 0.1em;
          color: #0D1824;
          text-align: center; }
        #top #service .service__list__item:nth-child(n+4) {
          margin-top: 2.2rem; }
  #top #gallery::before {
    background-color: #fff; }
  #top #gallery .container {
    padding: 2rem 0 8.05rem 0; }
  #top #gallery .gallery__slider {
    position: relative;
    margin-top: 2.875rem; }
    #top #gallery .gallery__slider__item {
      width: 6.55rem;
      margin-right: 0.25rem; }
    #top #gallery .gallery__slider__arrows {
      width: 6.175rem;
      height: 0.675rem;
      position: absolute;
      bottom: 0;
      transition: 0.3s ease all;
      cursor: pointer; }
      #top #gallery .gallery__slider__arrows:hover {
        width: 7.425rem; }
      #top #gallery .gallery__slider__arrows::after, #top #gallery .gallery__slider__arrows::before {
        content: "";
        display: inline-block;
        height: 0.05rem;
        background-color: #acabab;
        position: absolute; }
      #top #gallery .gallery__slider__arrows::before {
        width: calc(100% - 3.525rem);
        top: 50%; }
      #top #gallery .gallery__slider__arrows::after {
        width: 0.55rem;
        top: 25%; }
      #top #gallery .gallery__slider__arrows img {
        width: auto;
        height: 100%; }
      #top #gallery .gallery__slider__arrows.prev {
        right: 52.96875%; }
        #top #gallery .gallery__slider__arrows.prev::before {
          left: 0; }
        #top #gallery .gallery__slider__arrows.prev::after {
          left: -0.075rem;
          transform: rotate(-45deg); }
        #top #gallery .gallery__slider__arrows.prev img {
          margin-left: auto;
          margin-right: 0; }
      #top #gallery .gallery__slider__arrows.next {
        left: 52.96875%; }
        #top #gallery .gallery__slider__arrows.next::before {
          right: 0; }
        #top #gallery .gallery__slider__arrows.next::after {
          right: -0.075rem;
          transform: rotate(45deg); }
        #top #gallery .gallery__slider__arrows.next img {
          margin-left: 0;
          margin-right: auto; }
  #top #profile {
    background-color: #0F2878; }
    #top #profile::before {
      background-color: #0F2878; }
    #top #profile .container {
      padding: 1.9rem 0 4.15rem 0; }
    #top #profile .profile__box {
      background-color: #0F2878; }
    #top #profile .profile__title {
      width: 15.6rem;
      margin: 0 auto 3.3325rem; }
      #top #profile .profile__title::after {
        background-color: #fff; }
    #top #profile .profile__info {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start; }
      #top #profile .profile__info__txt {
        font-size: 0.35rem;
        line-height: 2.28571;
        letter-spacing: 0.1em;
        color: #fff;
        text-align: left;
        padding-left: 4.075rem;
        padding-right: 3.675rem;
        margin-bottom: 1.5625rem; }
        #top #profile .profile__info__txt li {
          display: flex;
          justify-content: flex-start;
          align-items: flex-start; }
          #top #profile .profile__info__txt li span {
            width: 2.375rem; }
          #top #profile .profile__info__txt li p {
            flex: 1; }
      #top #profile .profile__info__txt:last-child {
        padding-left: 0rem;
        padding-right: 0rem;
        margin-bottom: 0rem; }
    #top #profile .profile__map {
      width: 24rem;
      height: 9.75rem;
      margin: 0 auto;
      position: relative; }
      #top #profile .profile__map iframe {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0; }
  #top #contact {
    background-color: #F1EFE8; }
    #top #contact .container {
      padding: 1.4875rem 0 3.0825rem; }
    #top #contact .feature__title {
      width: 8.15rem;
      margin: 0 auto 1.4625rem; }
      #top #contact .feature__title::after {
        top: 2.865rem; }
    #top #contact .feature__sub {
      margin-bottom: 3.03rem; }
    #top #contact .row {
      width: 23.7rem;
      margin: 0 auto; }
      #top #contact .row .contact-form {
        padding-right: 2.9rem; }
      #top #contact .row .label-item {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 2.1rem; }
        #top #contact .row .label-item input,
        #top #contact .row .label-item textarea {
          font-size: 0.35rem;
          line-height: 2.28571;
          letter-spacing: 0.1em;
          font-family: source-han-sans-japanese, sans-serif;
          padding: 0 0.775rem; }
          #top #contact .row .label-item input::-webkit-input-placeholder,
          #top #contact .row .label-item textarea::-webkit-input-placeholder {
            color: #9A9898; }
        #top #contact .row .label-item textarea {
          resize: none;
          padding: 0.5825rem 0.775rem; }
        #top #contact .row .label-item span.wpcf7-not-valid-tip {
          margin-top: 0.35rem; }
        #top #contact .row .label-item .required {
          margin-top: 0.5rem; }
          #top #contact .row .label-item .required span {
            position: relative;
            display: inline-block;
            width: 5.625rem;
            font-size: 0.35rem;
            line-height: 2.28571;
            letter-spacing: 0.1em;
            color: #3e3a39;
            padding-right: 0.6rem;
            padding-left: 2.25rem; }
            #top #contact .row .label-item .required span::before {
              content: "必須";
              display: block;
              width: 1.65rem;
              height: 0.82675rem;
              font-size: 0.35rem;
              line-height: 2.28571;
              letter-spacing: 0.1em;
              color: #fff;
              font-family: source-han-sans-japanese, sans-serif;
              text-align: center;
              background-color: #0F2878;
              position: absolute;
              top: 50%;
              left: 0;
              transform: translateY(-50%); }
        #top #contact .row .label-item .not_required {
          margin-top: 0.5rem; }
          #top #contact .row .label-item .not_required span {
            position: relative;
            display: block;
            width: 5.625rem;
            font-size: 0.35rem;
            line-height: 2.28571;
            letter-spacing: 0.1em;
            color: #3e3a39;
            padding-right: 0.6rem; }
        #top #contact .row .label-item .your-name-mei input,
        #top #contact .row .label-item .your-name-sei input {
          width: 7rem;
          height: 1.65rem; }
        #top #contact .row .label-item .tel-877 input,
        #top #contact .row .label-item .your-email input {
          width: 15rem;
          height: 1.65rem; }
        #top #contact .row .label-item .your-message textarea {
          width: 15rem;
          height: 7.25rem; }
      #top #contact .row .name {
        display: flex;
        justify-content: flex-start;
        align-items: center; }
        #top #contact .row .name .label-item:nth-child(1) {
          padding-right: 1rem; }
      #top #contact .row .label-item:nth-child(5) {
        margin-bottom: 1.87rem; }
      #top #contact .row .submit_contact {
        margin-left: auto;
        width: 15.05rem;
        margin-bottom: 1.5125rem; }
        #top #contact .row .submit_contact div.wpcf7-acceptance-missing,
        #top #contact .row .submit_contact div.wpcf7-validation-errors {
          padding: 0.5rem; }
      #top #contact .row .address-item {
        margin-left: auto;
        width: 12.125rem;
        margin: 0 auto; }
        #top #contact .row .address-item .address-txt {
          font-size: 0.4rem;
          line-height: 2;
          letter-spacing: 0.1em;
          color: #3e3a39;
          margin-bottom: 0.73rem; }
        #top #contact .row .address-item .address-content {
          font-size: 0.4rem;
          line-height: 2;
          letter-spacing: 0.1em;
          color: #3e3a39; } }

@media (max-width: 768px) {
  #top .container {
    margin: 0 auto;
    max-width: 32rem;
    position: relative; }
  #top .sec {
    position: relative;
    z-index: 1; }
    #top .sec-title {
      text-align: center;
      width: auto;
      height: 1.792rem;
      margin: 0 auto 4.43733rem;
      position: relative; }
      #top .sec-title h2 {
        display: inline-block;
        height: 100%; }
      #top .sec-title img {
        width: auto;
        height: 100%; }
      #top .sec-title span {
        display: none !important; }
      #top .sec-title::after {
        content: '';
        display: inline-block;
        width: 3.92533rem;
        height: 0.256rem;
        background-color: #0F2878;
        position: absolute;
        left: 50%;
        bottom: -2.56rem;
        transform: translateX(-50%); }
    #top .sec-sub {
      font-size: 1.70667rem;
      line-height: 1.9;
      letter-spacing: 0.075em;
      color: #3e3a39;
      text-align: center;
      margin-bottom: 2.13333rem; }
    #top .sec-txt {
      font-size: 1.19467rem;
      line-height: 2.57143;
      letter-spacing: 0.12em;
      color: #3e3a39;
      padding: 0 2.98667rem;
      margin: 0 auto; }
  #top .img-parallax {
    width: 100%;
    height: 35.84rem;
    margin: 0 auto;
    position: relative;
    z-index: 0;
    overflow: hidden; }
    #top .img-parallax img {
      width: 100%;
      max-width: none;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: -1; }
    #top .img-parallax + .sec::before {
      content: "";
      display: inline-block;
      position: absolute;
      top: -4.8rem;
      right: 0;
      width: 28.58667rem;
      height: 9.6rem;
      z-index: 0; }
  #top #kv .container {
    max-height: calc(100vh - 5.97333rem); }
  #top #kv .kv__slider {
    width: 100%;
    height: 55.63733rem;
    max-height: calc(100vh - 8.53333rem);
    margin-bottom: 0.85333rem;
    z-index: 1; }
    #top #kv .kv__slider .slick-list,
    #top #kv .kv__slider .slick-track {
      height: 100%; }
    #top #kv .kv__slider__item {
      width: 100%;
      position: relative; }
      #top #kv .kv__slider__item img {
        width: 100%;
        height: 100%;
        object-fit: cover; }
  #top #kv .kv__info {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 13.22667rem;
    height: 6.74133rem;
    padding-bottom: 2.38933rem;
    background-color: #fff;
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 1; }
    #top #kv .kv__info__num {
      width: auto;
      height: 0.85333rem; }
      #top #kv .kv__info__num img {
        width: auto;
        height: 100%; }
    #top #kv .kv__info__timebar {
      width: 2.90133rem;
      height: 0.08533rem;
      background-color: #cecdcd;
      margin: 0 1.536rem;
      position: relative; }
      #top #kv .kv__info__timebar i {
        display: inline-block;
        width: 0;
        height: 100%;
        background-color: #0F2878;
        position: absolute;
        top: 0;
        left: 0; }
  #top #kv .kv__copy {
    width: 11.33653rem;
    margin-left: 1.70667rem; }
  #top #concept .container {
    padding: 5.97333rem 0 10.24rem; }
  #top #concept .concept__sub {
    margin-bottom: 4.352rem; }
  #top #business .container {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 50%;
    z-index: 3;
    transform: translateX(-50%); }
  #top #business .business__img {
    width: 100%;
    height: 64.85333rem; }
    #top #business .business__img img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
  #top #business .business__box {
    position: absolute;
    top: 5.12rem;
    left: 2.13333rem;
    width: 27.73333rem;
    padding: 1.31413rem;
    background-color: rgba(255, 255, 255, 0.8); }
    #top #business .business__box__inner {
      border: 2px solid;
      border-color: #0F2878;
      padding-top: 4.52267rem;
      padding-bottom: 5.05173rem; }
      #top #business .business__box__inner__title {
        position: relative;
        width: 17.06667rem;
        margin: 0 auto 3.5584rem; }
        #top #business .business__box__inner__title span {
          display: none !important; }
        #top #business .business__box__inner__title::after {
          content: '';
          position: absolute;
          left: 50%;
          top: 3.8144rem;
          transform: translateX(-50%);
          display: inline-block;
          width: 3.92533rem;
          height: 3px;
          background-color: #0F2878; }
      #top #business .business__box__inner__sub {
        font-size: 1.36533rem;
        line-height: 1.75;
        letter-spacing: 0.075em;
        color: #646161;
        text-align: center;
        margin: 0 auto 2.50027rem; }
      #top #business .business__box__inner__logo {
        width: 17.92rem;
        margin: 0 auto 1.62987rem; }
      #top #business .business__box__inner__txt {
        font-size: 1.024rem;
        line-height: 1.83333;
        letter-spacing: 0.2em;
        color: #0F2878;
        text-align: center;
        margin: 0 auto 1.6896rem; }
      #top #business .business__box__inner__img {
        width: 20.79232rem;
        margin: 0 auto; }
  #top #about {
    background-color: #F1EFE8; }
    #top #about::before {
      background-color: #F1EFE8; }
    #top #about .container {
      padding: 5.44rem 0 3.84rem; }
  #top #message {
    background-color: #F1EFE8;
    overflow: hidden; }
    #top #message .container {
      padding: 3.84rem 0 5.376rem; }
    #top #message .message__bg {
      width: 75.69067rem;
      height: auto;
      position: absolute;
      bottom: 22.18667rem;
      left: calc(0% - 75.69067rem);
      transform: translateX(107.69067rem);
      z-index: -1; }
    #top #message .message__content {
      padding: 0 2.98667rem;
      margin: 0 auto; }
      #top #message .message__content__txt {
        font-size: 1.19467rem;
        line-height: 2.57143;
        letter-spacing: 0.12em;
        color: #3e3a39;
        margin-bottom: 2.56rem; }
      #top #message .message__content__imges__img {
        width: 12.8rem;
        margin-left: auto; }
      #top #message .message__content__imges__txt {
        font-size: 0.93867rem;
        line-height: 1.72727;
        letter-spacing: 0.1em;
        color: #3e3a39;
        text-align: right;
        padding-top: 0.8192rem; }
  #top #feature {
    background-color: #fff; }
    #top #feature .container {
      padding: 5.97333rem 0 0; }
    #top #feature .feature__sub {
      margin-bottom: 4.3008rem; }
    #top #feature .feature__block {
      width: 100%;
      height: 74.24rem;
      padding-top: 16.21333rem;
      position: relative;
      overflow: hidden; }
      #top #feature .feature__block__doc {
        width: 26.45333rem;
        height: 100%;
        padding: 4.096rem 3.41333rem 0;
        background-color: #fff;
        z-index: 2; }
        #top #feature .feature__block__doc__title {
          width: auto;
          height: 1.64693rem;
          margin-bottom: 5.97333rem; }
          #top #feature .feature__block__doc__title img {
            width: auto;
            height: 100%;
            margin-left: 0;
            margin-right: auto; }
        #top #feature .feature__block__doc__box {
          position: relative; }
          #top #feature .feature__block__doc__box__sub {
            font-size: 1.536rem;
            letter-spacing: 0.075em;
            color: #3e3a39;
            margin-bottom: 2.34667rem; }
          #top #feature .feature__block__doc__box__txt {
            font-size: 1.10933rem;
            line-height: 2.30769;
            letter-spacing: 0.075em;
            color: #3e3a39; }
      #top #feature .feature__block__img {
        width: 28.58667rem;
        position: absolute;
        top: 0;
        z-index: -1; }
        #top #feature .feature__block__img img {
          height: 74.24rem; }
      #top #feature .feature__block.block01 .feature__block__doc, #top #feature .feature__block.block03 .feature__block__doc {
        margin-right: auto;
        margin-left: 0; }
      #top #feature .feature__block.block01 .feature__block__img, #top #feature .feature__block.block03 .feature__block__img {
        right: 0; }
      #top #feature .feature__block.block02 .feature__block__doc {
        margin-right: 0;
        margin-left: auto; }
      #top #feature .feature__block.block02 .feature__block__img {
        left: 0; }
  #top #service {
    background-color: #F1EFE8; }
    #top #service .container {
      padding: 7.68rem 0 10.24rem; }
    #top #service .service__sub {
      margin-bottom: 1.6896rem; }
    #top #service .service__txt {
      font-size: 1.10933rem;
      line-height: 2.30769;
      letter-spacing: 0.075em;
      text-align: left; }
    #top #service .service__list {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-wrap: wrap;
      width: 100%;
      padding: 0 3.41333rem;
      margin: 5.03467rem auto 0; }
      #top #service .service__list__item {
        width: calc(50% - 1.45067rem); }
        #top #service .service__list__item__img {
          width: 100%;
          margin-bottom: 1.22027rem; }
        #top #service .service__list__item__title {
          font-size: 1.10933rem;
          letter-spacing: 0.12em;
          color: #0D1824;
          text-align: center;
          white-space: nowrap; }
        #top #service .service__list__item:nth-child(odd) {
          margin-right: 2.90133rem; }
        #top #service .service__list__item:nth-child(n+3) {
          margin-top: 5.12rem; }
  #top #gallery {
    background-color: #fff; }
    #top #gallery::before {
      background-color: #fff; }
    #top #gallery .container {
      padding: 5.97333rem 0 7.168rem; }
    #top #gallery .gallery__slider {
      position: relative;
      margin-top: 5.12rem; }
      #top #gallery .gallery__slider__item {
        width: 17.88587rem;
        margin-right: 0.85333rem; }
      #top #gallery .gallery__slider__arrows {
        width: 10.752rem;
        height: 1.19467rem;
        position: absolute;
        bottom: 0;
        cursor: pointer; }
        #top #gallery .gallery__slider__arrows::after, #top #gallery .gallery__slider__arrows::before {
          content: "";
          display: inline-block;
          height: 0.17067rem;
          background-color: #acabab;
          position: absolute; }
        #top #gallery .gallery__slider__arrows::before {
          width: calc(100% - 5.80267rem);
          top: 50%; }
        #top #gallery .gallery__slider__arrows::after {
          width: 1.536rem;
          top: 13%; }
        #top #gallery .gallery__slider__arrows img {
          width: auto;
          height: 100%; }
        #top #gallery .gallery__slider__arrows.prev {
          right: 55.2%; }
          #top #gallery .gallery__slider__arrows.prev::before {
            left: 0; }
          #top #gallery .gallery__slider__arrows.prev::after {
            left: -0.17067rem;
            transform: rotate(-45deg); }
          #top #gallery .gallery__slider__arrows.prev img {
            margin-left: auto;
            margin-right: 0; }
        #top #gallery .gallery__slider__arrows.next {
          left: 55.2%; }
          #top #gallery .gallery__slider__arrows.next::before {
            right: 0; }
          #top #gallery .gallery__slider__arrows.next::after {
            right: -0.256rem;
            transform: rotate(45deg); }
          #top #gallery .gallery__slider__arrows.next img {
            margin-left: 0;
            margin-right: auto; }
  #top #profile {
    background-color: #0F2878; }
    #top #profile::before {
      background-color: #0F2878; }
    #top #profile .container {
      padding: 4.8rem 0 4.26667rem; }
    #top #profile .profile__title {
      height: 4.94933rem;
      margin-bottom: 7.33867rem; }
      #top #profile .profile__title::after {
        background-color: #fff; }
    #top #profile .profile__info {
      width: 100%;
      padding: 0 3.41333rem;
      margin: 0 auto 3.62667rem; }
      #top #profile .profile__info__txt {
        font-size: 1.10933rem;
        line-height: 2.30769;
        letter-spacing: 0.1em;
        color: #fff;
        text-align: left; }
        #top #profile .profile__info__txt li {
          display: flex;
          justify-content: flex-start;
          align-items: flex-start; }
          #top #profile .profile__info__txt li span {
            width: 7.50933rem; }
          #top #profile .profile__info__txt li p {
            flex: 1; }
    #top #profile .profile__map {
      width: 25.17333rem;
      height: 25.17333rem;
      margin: 0 auto;
      position: relative; }
      #top #profile .profile__map iframe {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0; }
  #top #contact {
    background-color: #F1EFE8; }
    #top #contact .container {
      padding: 7.04rem 0 0; }
    #top #contact .contact__sub {
      margin-bottom: 1.6896rem; }
    #top #contact .contact__txt {
      margin-bottom: 4.1472rem; }
    #top #contact .row {
      width: 25.17333rem;
      margin: 0 auto;
      padding-bottom: 9.2672rem; }
      #top #contact .row .label-item {
        display: inline-block; }
        #top #contact .row .label-item .required span {
          position: relative;
          display: inline-block;
          font-size: 1.10933rem;
          line-height: 2.46154;
          letter-spacing: 0.1em;
          color: #3e3a39;
          padding-left: 5.632rem;
          margin-bottom: 0.73387rem; }
          #top #contact .row .label-item .required span::before {
            content: "必須";
            display: block;
            width: 4.77867rem;
            height: 1.96267rem;
            font-size: 1.10933rem;
            line-height: 1.96267rem;
            letter-spacing: 0.1em;
            color: #fff;
            font-family: source-han-sans-japanese, sans-serif;
            text-align: center;
            background-color: #0F2878;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%); }
        #top #contact .row .label-item .not_required span {
          position: relative;
          display: block;
          font-size: 1.19467rem;
          line-height: 2.28571;
          letter-spacing: 0.1em;
          color: #3e3a39; }
        #top #contact .row .label-item .wpcf7-form-control-wrap {
          display: inline-block; }
        #top #contact .row .label-item input,
        #top #contact .row .label-item textarea {
          font-size: 1.19467rem;
          line-height: 2.28571;
          letter-spacing: 0.1em;
          font-family: source-han-sans-japanese, sans-serif;
          padding: 0 1.10933rem; }
          #top #contact .row .label-item input::-webkit-input-placeholder,
          #top #contact .row .label-item textarea::-webkit-input-placeholder {
            color: #9A9898; }
        #top #contact .row .label-item textarea {
          resize: none;
          padding: 0.8704rem 1.10933rem; }
        #top #contact .row .label-item span.wpcf7-not-valid-tip {
          margin-top: 0.512rem; }
        #top #contact .row .label-item .your-name-sei {
          margin-bottom: 0.68267rem; }
          #top #contact .row .label-item .your-name-sei input {
            width: 25.17333rem;
            height: 3.41333rem; }
        #top #contact .row .label-item .your-email,
        #top #contact .row .label-item .your-name-mei {
          margin-bottom: 2.56rem; }
          #top #contact .row .label-item .your-email input,
          #top #contact .row .label-item .your-name-mei input {
            width: 25.17333rem;
            height: 3.41333rem; }
        #top #contact .row .label-item .tel-877 {
          margin-bottom: 2.3296rem; }
          #top #contact .row .label-item .tel-877 input {
            width: 25.17333rem;
            height: 3.41333rem; }
        #top #contact .row .label-item .your-message {
          margin-bottom: 5.12rem; }
          #top #contact .row .label-item .your-message textarea {
            width: 25.17333rem;
            height: 25.17333rem; }
      #top #contact .row .submit_contact {
        margin-left: auto;
        width: 25.17333rem;
        margin-bottom: 4.89813rem; }
        #top #contact .row .submit_contact div.wpcf7-acceptance-missing,
        #top #contact .row .submit_contact div.wpcf7-validation-errors {
          padding: 1.70667rem; }
      #top #contact .row .address-item {
        width: 25.17333rem; }
        #top #contact .row .address-item .address-txt {
          font-size: 1.10933rem;
          line-height: 2.30769;
          letter-spacing: 0.1em;
          color: #3e3a39;
          margin-bottom: 1.36533rem; }
        #top #contact .row .address-item .address-content {
          font-size: 1.10933rem;
          line-height: 2.30769;
          letter-spacing: 0.1em;
          color: #3e3a39; } }
