#top {
  .container {
    width: 100%;
    max-width: cal($pcsize, pc);
    position: relative;
  }

  .sec {
    position: relative;
    z-index: 1;

    &-title {
      position: relative;
      color: #646161;
      text-align: center;
      height: cal(40.5, pc);
      margin-bottom: cal(77, pc);

      h2 {
        display: inline-block;
        height: 100%;
      }

      span {
        display: none !important;
      }

      img {
        width: auto;
        height: 100%;
      }

      &::after {
        content: '';
        position: absolute;
        left: 50%;
        bottom: cal(-47, pc);
        transform: translateX(-50%);
        display: inline-block;
        width: cal(56, pc);
        height: cal(5, pc);
        background-color: $third-background-color;
      }
    }

    &-sub {
      font-size: cal(26, pc);
      line-height: (50 / 26);
      letter-spacing: 0.075em;
      color: $text-color;
      text-align: center;
      margin-bottom: cal(61.5, pc);
    }

    &-txt {
      font-size: cal(15, pc);
      line-height: (40 / 15);
      letter-spacing: 0.1em;
      color: $text-color;
      text-align: left;
      width: 100%;
      padding: 0 cal(188, pc);
      margin: 0 auto;
    }
  }

  .img-parallax {
    width: 100%;
    height: cal(618, pc);
    position: relative;
    z-index: 0;
    overflow: hidden;

    &.parallax01 {
      img {
        transform: translate(-50%, -80%);
      }
    }

    img {
      width: 100%;
      max-width: none;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -65%);
      z-index: -1;
    }

    + .sec {
      // .container {
      // }
      &::before {
        content: "";
        display: inline-block;
        width: cal(1092, pc);
        height: cal(160, pc);
        position: absolute;
        top: cal(-80, pc);
        right: 0;
        z-index: 0;
      }
    }
  }

  #kv {
    margin-top: cal(-72, pc);

    .kv {
      &__slider {
        width: cal(1092, pc);
        height: cal(622, pc);
        margin-right: 0;
        margin-left: auto;
        z-index: 1;

        &__item {
          width: 100%;
          position: relative;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }

      &__info {
        display: flex;
        align-items: center;
        justify-content: center;
        width: cal(222, pc);
        height: cal(73, pc);
        background-color: $color-white;
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: 1;

        &__num {
          width: auto;
          height: cal(10.7, pc);

          img {
            width: auto;
            height: 100%;
          }
        }

        &__timebar {
          width: cal(34, pc);
          height: 1px;
          background-color: $kv-timebar-color;
          margin: 0 cal(16, pc);
          position: relative;

          i {
            display: inline-block;
            width: 0;
            height: 100%;
            background-color: $third-background-color;
            position: absolute;
            top: 0;
            left: 0;
          }
        }
      }
    }
  }

  #concept {
    .container {
      padding: cal(169, pc) 0 cal(181, pc) 0;
    }
  }

  #business {
    .container {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 50%;
      z-index: 3;
      transform: translateX(-50%);
    }

    .business {
      &__img {
        width: 100%;
        height: cal(853.33, pc);

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      &__box {
        position: absolute;
        top: cal(127.6, pc);
        left: cal(168, pc);
        width: cal(944, pc);
        padding: cal(18.4, pc);
        background-color: rgba(255,255,255,0.8);

        &__inner {
          border: 2px solid;
          border-color: $third-background-color;
          padding-top: cal(69, pc);
          padding-bottom: cal(71.4, pc);

          &__title {
            position: relative;
            width: cal(286, pc);
            margin: 0 auto cal(56.6, pc);

            span {
              display: none !important;
            }

            &::after {
              content: '';
              position: absolute;
              left: 50%;
              top: cal(54.2, pc);
              transform: translateX(-50%);
              display: inline-block;
              width: cal(49.5, pc);
              height: 3.54px;
              background-color: $third-background-color;
            }
          }

          &__sub {
            br {
              display: none;
            }
            font-size: cal(21.21, pc);
            letter-spacing: 0.075em;
            color: #646161;
            text-align: center;
            margin: 0 auto cal(49, pc);
          }

          &__logo {
            width: cal(367.92, pc);
            margin: 0 auto cal(22.5, pc);
          }

          &__txt {
            br {
              display: none;
            }
            width: cal(340, pc);
            font-size: cal(14, pc);
            line-height: (26 / 14);
            letter-spacing: 0.2em;
            color: $third-background-color;
            text-align: center;
            margin: 0 auto;
          }

          &__img {
            width: cal(688.62, pc);
            margin: cal(-58, pc) auto 0;
          }
        }
      }
    }
  }

  #about {
    background-color: $secondry-background-color;

    &::before {
      background-color: $secondry-background-color;
    }

    .container {
      padding: cal(80, pc) 0 cal(113, pc);
    }

    .about {
      &__sub {
        margin-bottom: cal(39.8, pc);
      }
    }
  }

  #message {
    background-color: $secondry-background-color;
    overflow: hidden;

    .container {
      padding: cal(113, pc) 0 cal(230, pc);
    }

    .message {
      &__bg {
        width: cal(2035, pc);
        height: auto;
        position: absolute;
        bottom: cal(127, pc);
        left: calc(50% - #{cal(2035, pc)});
        transform: translateX(#{cal(2035, pc)});
        z-index: -1;
      }

      &__sub {
        text-align: left;
        width: 100%;
        padding: 0 cal(188, pc);
        margin: 0 auto cal(36.8, pc);
      }

      &__content {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        padding: 0 cal(188, pc);
        margin: 0 auto;

        &__txt {
          width: cal(596, pc);
          font-size: cal(15, pc);
          line-height: (40 / 15);
          letter-spacing: 0.1em;
          color: $text-color;
          margin-right: auto;
        }

        &__imges {
          &__img {
            width: cal(230, pc);
            margin-left: auto;
          }

          &__txt {
            font-size: cal(13, pc);
            line-height: (18 / 13);
            letter-spacing: 0.1em;
            color: $text-color;
            text-align: right;
            padding-top: cal(15.4, pc);
          }
        }
      }
    }
  }

  #feature {
    background-color: $color-white;

    .container {
      padding: cal(161.9, pc) 0 0 0;
    }

    .feature {
      &__sub {
        margin-bottom: cal(126.6, pc);
      }

      &__block {
        position: relative;
        height: cal(620, pc);
        overflow: hidden;

        &__doc {
          width: cal(640, pc);

          &__title {
            width: cal(131, pc);
            // margin-bottom: cal(37, pc);
            margin-bottom: cal(64, pc);
          }

          &__box {
            position: relative;
            width: cal(452, pc);
            min-height: cal(482, pc);
            padding-top: cal(39.1, pc);
            padding-bottom: cal(29, pc);
            background-color: $color-white;
            z-index: 2;

            &__sub {
              font-size: cal(24, pc);
              letter-spacing: 0.075em;
              color: $text-color;
              margin-bottom: cal(43.2, pc);
            }

            &__txt {
              font-size: cal(14, pc);
              line-height: (34 / 14);
              letter-spacing: 0.075em;
              color: $text-color;
            }
          }
        }

        &__img {
          width: cal(868, pc);
          height: 100%;
          position: absolute;
          top: 0;
          z-index: 1;
          overflow: hidden;
        }

        &.block01,
        &.block03 {
          padding-top: cal(96, pc);

          .feature__block {
            &__doc {
              &__title {
                margin-left: cal(189, pc);
                margin-right: auto;
              }

              &__box {
                padding-right: cal(52, pc);
                margin-right: 0;
                margin-left: auto;
              }
            }

            &__img {
              right: 0;
            }
          }
        }

        &.block02 {
          padding-top: cal(80, pc);

          .feature__block {
            &__doc {
              margin-right: 0;
              margin-left: auto;

              &__title {
                margin-right: cal(180, pc);
                margin-left: auto;
              }

              &__box {
                padding-left: cal(52, pc);
                padding-bottom: cal(0, pc);
                margin-right: auto;
                margin-left: 0;
              }
            }

            &__img {
              left: 0;
            }
          }
        }
      }
    }
  }

  #service {
    background-color: $secondry-background-color;

    .container {
      padding: cal(205, pc) 0 cal(220, pc);
    }

    .service {
      &__txt {
        text-align: center;
      }

      &__list {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
        width: 100%;
        padding: 0 cal(162, pc);
        margin: cal(100, pc) auto 0;

        &__item {
          width: calc(33.3% - #{cal(52, pc)});
          margin: 0 cal(26, pc);

          &__img {
            width: 100%;
            margin-bottom: cal(26.3, pc);
          }

          &__title {
            font-size: cal(18, pc);
            letter-spacing: 0.1em;
            color: #0D1824;
            text-align: center;
          }

          &:nth-child(n+4) {
            margin-top: cal(88, pc);
          }
        }
      }
    }
  }

  #gallery {
    &::before {
      background-color: $color-white;
    }

    .container {
      padding: cal(80, pc) 0 cal(322, pc) 0;
    }

    .gallery {
      &__slider {
        position: relative;
        // padding-bottom: cal(94, pc);
        margin-top: cal(115, pc);

        &__item {
          width: cal(262, pc);
          margin-right: cal(10, pc);
        }

        &__arrows {
          width: cal(247, pc);
          height: cal(27, pc);
          position: absolute;
          bottom: 0;
          transition: 0.3s ease all;
          cursor: pointer;

          &:hover {
            width: cal(297, pc);
          }

          &::after,
          &::before {
            content: "";
            display: inline-block;
            height: cal(2, pc);
            background-color: $gallery-arrow-color;
            position: absolute;
          }

          &::before {
            width: calc(100% - #{cal(141, pc)});
            top: 50%;
          }

          &::after {
            width: cal(22, pc);
            top: 25%;
          }

          img {
            width: auto;
            height: 100%;
          }

          &.prev {
            right: 52.96875%;

            &::before {
              left: 0;
            }

            &::after {
              left: cal(-3, pc);
              transform: rotate(-45deg);
            }

            img {
              margin-left: auto;
              margin-right: 0;
            }
          }

          &.next {
            left: 52.96875%;

            &::before {
              right: 0;
            }

            &::after {
              right: cal(-3, pc);
              transform: rotate(45deg);
            }

            img {
              margin-left: 0;
              margin-right: auto;
            }
          }
        }
      }
    }
  }

  #profile {
    background-color: $third-background-color;

    &::before {
      background-color: $third-background-color;
    }

    .container {
      padding: cal(76, pc) 0 cal(166, pc) 0;
    }

    .profile {
      &__box {
        background-color: $third-background-color;
      }

      &__title {
        width: cal(624, pc);
        margin: 0 auto cal(133.3, pc);

        &::after {
          background-color: $color-white;
        }
      }

      &__info {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;

        &__txt {
          font-size: cal(14, pc);
          line-height: (32 / 14);
          letter-spacing: 0.1em;
          color: $color-white;
          text-align: left;
          padding-left: cal(163, pc);
          padding-right: cal(147, pc);
          margin-bottom: cal(62.5, pc);

          li {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;

            span {
              width: cal(95, pc);
            }

            p {
              flex: 1;
            }
          }
        }

        &__txt:last-child {
          padding-left: cal(0, pc);
          padding-right: cal(0, pc);
          margin-bottom: cal(0, pc);
        }
      }

      &__map {
        width: cal(960, pc);
        height: cal(390, pc);
        margin: 0 auto;
        position: relative;

        iframe {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }
  }

  #contact {
    background-color: $secondry-background-color;

    .container {
      padding: cal(59.5, pc) 0 cal(123.3, pc);
    }

    .feature {
      &__title {
        width: cal(326, pc);
        margin: 0 auto cal(58.5, pc);

        &::after {
          top: cal(114.6, pc);
        }
      }

      &__sub {
        margin-bottom: cal(121.2, pc);
      }
    }

    .row {
      width: cal(948, pc);
      margin: 0 auto;

      .contact-form {
        padding-right: cal(116, pc);
      }

      .label-item {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: cal(84, pc);

        input,
        textarea {
          font-size: cal(14, pc);
          line-height: (32 / 14);
          letter-spacing: 0.1em;
          font-family: $tertiary-font;
          padding: 0 cal(31, pc);

          &::-webkit-input-placeholder {
            color: #9A9898;
          }
        }

        textarea {
          resize: none;
          padding: cal(23.3, pc) cal(31,pc);
        }

        span.wpcf7-not-valid-tip {
          margin-top: cal(14, pc);
        }

        .required {
          margin-top: cal(20, pc);

          span {
            position: relative;
            display: inline-block;
            width: cal(225, pc);
            font-size: cal(14, pc);
            line-height: (32 / 14);
            letter-spacing: 0.1em;
            color: $text-color;
            padding-right: cal(24, pc);
            padding-left: cal(90, pc);

            &::before {
              content: "必須";
              display: block;
              width: cal(66, pc);
              height: cal(33.07, pc);
              font-size: cal(14, pc);
              line-height: (32 / 14);
              letter-spacing: 0.1em;
              color: $color-white;
              font-family: $tertiary-font;
              text-align: center;
              background-color: $third-background-color;
              position: absolute;
              top: 50%;
              left: 0;
              transform: translateY(-50%);
            }
          }
        }

        .not_required {
          margin-top: cal(20, pc);

          span {
            position: relative;
            display: block;
            width: cal(225, pc);
            font-size: cal(14, pc);
            line-height: (32 / 14);
            letter-spacing: 0.1em;
            color: $text-color;
            padding-right: cal(24, pc);
          }
        }

        .your-name-mei,
        .your-name-sei {
          input {
            width: cal(280, pc);
            height: cal(66, pc);
          }
        }

        .tel-877,
        .your-email {
          input {
            width: cal(600, pc);
            height: cal(66, pc);
          }
        }

        .your-message {
          textarea {
            width: cal(600, pc);
            height: cal(290, pc);
          }
        }
      }

      .name {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .label-item:nth-child(1) {
          padding-right: cal(40, pc);
        }
      }

      .label-item:nth-child(5) {
        margin-bottom: cal(74.8, pc);
      }

      .submit_contact {
        margin-left: auto;
        width: cal(602, pc);
        margin-bottom: cal(60.5, pc);

        div.wpcf7-acceptance-missing,
        div.wpcf7-validation-errors {
          padding: cal(20, pc);
        }
      }

      .address-item {
        margin-left: auto;
        width: cal(485, pc);
        margin: 0 auto;

        .address-txt {
          font-size: cal(16, pc);
          line-height: (32 / 16);
          letter-spacing: 0.1em;
          color: $text-color;
          margin-bottom: cal(29.2, pc);
        }

        .address-content {
          font-size: cal(16, pc);
          line-height: (32 / 16);
          letter-spacing: 0.1em;
          color: $text-color;
        }
      }
    }
  }
}
