header {
  .header {
    width: 100%;

    &__inner {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      width: 100%;
      max-width: cal($pcsize, pc);
      padding: cal(33, pc) cal(43, pc) cal(33, pc) cal(38, pc);
      margin: 0 auto;
    }

    &__logo {
      width: cal(345, pc);
      margin-right: auto;
      z-index: 2;
    }

    &__copyright {
      width: cal(131, pc);
      padding-top: cal(12, pc);
    }
  }
}
