aside {
  width: 100%;
  height: 100vh;
  background: $nav-background-color;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  transform: translateX(100%);
  opacity: 0;
  transition: 0.8s ease transform, 0.8s ease opacity;

  &.open {
    transform: translateX(0%);
    opacity: 1;
  }

  .aside {
    width: 100%;
    max-width: cal($spsize);
    height: 100%;
    padding: cal(30) cal(42);
    margin: 0 auto;
    position: relative;
    overflow: auto;

    &__bg {
      width: cal(104);
      height: auto;
      position: absolute;
      top: cal(34);
      left: 0;
      z-index: -1;
    }

    &__btn {
      display: inline-block;
      width: cal(33);
      height: cal(33);
      background-color: $hamburger-color;
      border-radius: 50%;
      position: absolute;
      top: cal(10);
      right: cal(10);
      z-index: 2;

      &::before {
        content: "";
        display: inline-block;
        width: cal(13);
        height: cal(26.5);
        background-color: $hamburger-color;
        position: absolute;
        top: cal(-10);
        left: 0;
        z-index: 0;
      }

      span {
        width: cal(21.2);
        height: cal(2);
        background-color: $nav-background-color;
        position: absolute;
        top: 50%;
        left: 50%;

        &:nth-child(1) {
          transform: translate(-50%, -50%) rotate(45deg);
        }

        &:nth-child(2) {
          transform: translate(-50%, -50%) rotate(-45deg);
        }
      }
    }

    &__link {
      padding: cal(113) 0 cal(42);
      margin-bottom: cal(52);

      li {
        text-align: right;
        padding-bottom: cal(12);

        a {
          display: inline-block;
        }

        &:nth-child(n + 2) {
          margin-top: cal(25);
        }

        &:nth-child(6) {
          img {
            height: cal(48);
          }
        }

        img {
          height: cal(16);
          width: auto;
          margin-right: 0;
        }
      }
    }

    &__sns {
      width: cal(20);
      margin-right: 0;
      margin-left: auto;
      margin-bottom: cal(43);
    }

    &__copy {
      width: cal(131);
      margin-right: 0;
      margin-left: auto;
    }
  }
}
