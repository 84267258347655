/*-----------------------------------


  LOADING SCREEN


-----------------------------------*/
@keyframes loading {
  0%,
  100%,
  80% {
    box-shadow: 0 2.5rem 0 -1.3rem;
  }

  40% {
    box-shadow: 0 2.5rem 0 0;
  }
}

.load_bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: $background-color;
  z-index: 100000;
  transform: translate3d(0, 0, 0);

  body.loaded & {
    visibility: hidden;
    opacity: 0;
    transition: 0.3s ease opacity, 0s visibility 0.3s;
  }

  &__contents:after,
  &__contents:before,
  &__contents {
    border-radius: 50%;
    width: 2.5em;
    height: 2.5em;
    animation-fill-mode: both;
    animation: loading 3s infinite ease-in-out;
    transform: translate3d(-0, 0, 0);
  }

  &__contents {
    color: $text-color;
    font-size: 10px;
    margin: 80px auto;
    position: absolute;
    left: 50%;
    top: calc(50% - 5rem);
    transform: translate3d(-50%, -50%, 0);
    animation-delay: -0.16s;
  }

  &__contents:after,
  &__contents:before {
    content: '';
    position: absolute;
    top: 0;
  }

  &__contents:before {
    left: -3.5rem;
    animation-delay: -0.32s;
  }

  &__contents:after {
    left: 3.5rem;
  }
}

/*-----------------------------------


  FADE IN


-----------------------------------*/

.js-fadeIn {
  visibility: hidden;
  opacity: 0;
  transform: translateY(50px);

  &.fadeIn {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
    transition: 0s visibility, 0.8s opacity ease, 0.8s transform ease;
  }
}
