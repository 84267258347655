header {
  height: cal(70);

  .header {
    width: 100%;
    height: cal(70);
    position: fixed;
    background: $color-white;
    z-index: 10000;

    &__inner {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      width: 100%;
      max-width: cal($spsize);
      padding: cal(18) cal(13) cal(8) cal(20);
      margin: 0 auto;
    }

    &__logo {
      width: cal(185.78);
      margin-right: auto;
      margin-bottom: cal(3.1);
    }

    &__hamburger {
      width: cal(21);
      height: cal(16);
      position: relative;
      cursor: pointer;

      span {
        display: inline-block;
        width: 100%;
        height: cal(2);
        background-color: $hamburger-color;
        position: absolute;
        left: 0;

        &:nth-child(1) {
          top: 0;
        }

        &:nth-child(2) {
          top: calc(50% - #{cal(1)});
        }

        &:nth-child(1) {
          top: calc(100% - #{cal(2)});
        }
      }
    }
  }
}
