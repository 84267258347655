aside {
  position: absolute;
  top: cal(263, pc);
  left: 0;
  z-index: 100;
  @include mq(min, $max-width) {
    left: calc((100vw - #{$max-width}) / 2);
  }

  .aside {
    padding-left: cal(30, pc);

    &__link {
      margin-bottom: cal(52, pc);

      li {
        padding-bottom: cal(10, pc);

        &:nth-child(n + 2) {
          margin-top: cal(22, pc);
        }

        &:nth-child(6) {
          img {
            height: cal(36, pc);
          }
        }

        img {
          height: cal(11, pc);
          width: auto;
          margin-left: 0;
        }
      }
    }

    &__sns {
      width: cal(20, pc);
    }
  }
}
