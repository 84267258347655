#top {
  .container {
    margin: 0 auto;
    max-width: cal($spsize);
    position: relative;
  }

  .sec {
    position: relative;
    z-index: 1;

    &-title {
      text-align: center;
      width: auto;
      height: cal(21);
      margin: 0 auto cal(52);
      position: relative;

      h2 {
        display: inline-block;
        height: 100%;
      }

      img {
        width: auto;
        height: 100%;
      }

      span {
        display: none !important;
      }

      &::after {
        content: '';
        display: inline-block;
        width: cal(46);
        height: cal(3);
        background-color: $third-background-color;
        position: absolute;
        left: 50%;
        bottom: cal(-30);
        transform: translateX(-50%);
      }
    }

    &-sub {
      font-size: cal(20);
      line-height: (38 / 20);
      letter-spacing: 0.075em;
      color: $text-color;
      text-align: center;
      margin-bottom: cal(25);
    }

    &-txt {
      font-size: cal(14);
      line-height: (36 / 14);
      letter-spacing: 0.12em;
      color: $text-color;
      padding: 0 cal(35);
      margin: 0 auto;
    }
  }

  .img-parallax {
    width: 100%;
    height: cal(420);
    margin: 0 auto;
    position: relative;
    z-index: 0;
    overflow: hidden;

    img {
      width: 100%;
      max-width: none;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: -1;
    }

    + .sec {
      // .container {
      //   }
      &::before {
        content: "";
        display: inline-block;
        position: absolute;
        top: cal(-56.25);
        right: 0;
        width: cal(335);
        height: cal(112.5);
        z-index: 0;
      }
    }
  }

  #kv {
    .container {
      max-height: calc(100vh - #{cal(70)});
    }

    .kv {
      &__slider {
        width: 100%;
        height: cal(652);
        max-height: calc(100vh - #{cal(100)});
        margin-bottom: cal(10);
        z-index: 1;

        .slick-list,
        .slick-track {
          height: 100%;
        }

        &__item {
          width: 100%;
          position: relative;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }

      &__info {
        display: flex;
        align-items: center;
        justify-content: center;
        width: cal(155);
        height: cal(79);
        padding-bottom: cal(28);
        background-color: $color-white;
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: 1;

        &__num {
          width: auto;
          height: cal(10);

          img {
            width: auto;
            height: 100%;
          }
        }

        &__timebar {
          width: cal(34);
          height: cal(1);
          background-color: $kv-timebar-color;
          margin: 0 cal(18);
          position: relative;

          i {
            display: inline-block;
            width: 0;
            height: 100%;
            background-color: $third-background-color;
            position: absolute;
            top: 0;
            left: 0;
          }
        }
      }

      &__copy {
        width: cal(132.85);
        margin-left: cal(20);
      }
    }
  }

  #concept {
    .container {
      padding: cal(70) 0 cal(120);
    }

    .concept {
      &__sub {
        margin-bottom: cal(51);
      }
    }
  }

  #business {
    .container {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 50%;
      z-index: 3;
      transform: translateX(-50%);
    }

    .business {
      &__img {
        width: 100%;
        height: cal(760);

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      &__box {
        position: absolute;
        top: cal(60);
        left: cal(25);
        width: cal(325);
        padding: cal(15.4);
        background-color: rgba(255,255,255,0.8);

        &__inner {
          border: 2px solid;
          border-color: $third-background-color;
          padding-top: cal(53);
          padding-bottom: cal(59.2);

          &__title {
            position: relative;
            width: cal(200);
            margin: 0 auto cal(41.7);

            span {
              display: none !important;
            }

            &::after {
              content: '';
              position: absolute;
              left: 50%;
              top: cal(44.7);
              transform: translateX(-50%);
              display: inline-block;
              width: cal(46);
              height: 3px;
              background-color: $third-background-color;
            }
          }

          &__sub {
            font-size: cal(16);
            line-height: (28 / 16);
            letter-spacing: 0.075em;
            color: #646161;
            text-align: center;
            margin: 0 auto cal(29.3);
          }

          &__logo {
            width: cal(210);
            margin: 0 auto cal(19.1);
          }

          &__txt {
            font-size: cal(12);
            line-height: (22 / 12);
            letter-spacing: 0.2em;
            color: $third-background-color;
            text-align: center;
            margin: 0 auto cal(19.8);
          }

          &__img {
            width: cal(243.66);
            margin: 0 auto;
          }
        }
      }
    }
  }

  #about {
    background-color: $secondry-background-color;

    &::before {
      background-color: $secondry-background-color;
    }

    .container {
      padding: cal(63.75) 0 cal(45);
    }
  }

  #message {
    background-color: $secondry-background-color;
    overflow: hidden;

    .container {
      padding: cal(45) 0 cal(63);
    }

    .message {
      &__bg {
        width: cal(887);
        height: auto;
        position: absolute;
        bottom: cal(260);
        left: calc(0% - #{cal(887)});
        transform: translateX(#{cal(1262)});
        z-index: -1;
      }

      &__content {
        padding: 0 cal(35);
        margin: 0 auto;

        &__txt {
          font-size: cal(14);
          line-height: (36 / 14);
          letter-spacing: 0.12em;
          color: $text-color;
          margin-bottom: cal(30);
        }

        &__imges {
          &__img {
            width: cal(150);
            margin-left: auto;
          }

          &__txt {
            font-size: cal(11);
            line-height: (19 / 11);
            letter-spacing: 0.1em;
            color: $text-color;
            text-align: right;
            padding-top: cal(9.6);
          }
        }
      }
    }
  }

  #feature {
    background-color: $color-white;

    .container {
      padding: cal(70) 0 0;
    }

    .feature {
      &__sub {
        margin-bottom: cal(50.4);
      }

      &__block {
        width: 100%;
        height: cal(870);
        padding-top: cal(190);
        position: relative;
        overflow: hidden;

        &__doc {
          width: cal(310);
          height: 100%;
          // padding: cal(34.5) cal(40) 0;
          padding: cal(48) cal(40) 0;
          background-color: $color-white;
          z-index: 2;

          &__title {
            width: auto;
            // height: cal(38.38);
            height: cal(19.3);
            margin-bottom: cal(70);

            img {
              width: auto;
              height: 100%;
              margin-left: 0;
              margin-right: auto;
            }
          }

          &__box {
            position: relative;

            &__sub {
              font-size: cal(18);
              letter-spacing: 0.075em;
              color: $text-color;
              margin-bottom: cal(27.5);
            }

            &__txt {
              font-size: cal(13);
              line-height: (30 / 13);
              letter-spacing: 0.075em;
              color: $text-color;
            }
          }
        }

        &__img {
          width: cal(335);
          position: absolute;
          top: 0;
          z-index: -1;

          img {
            height: cal(870);
          }
        }

        &.block01,
        &.block03 {
          .feature__block {
            &__doc {
              margin-right: auto;
              margin-left: 0;
            }

            &__img {
              right: 0;
            }
          }
        }

        &.block02 {
          .feature__block {
            &__doc {
              margin-right: 0;
              margin-left: auto;
            }

            &__img {
              left: 0;
            }
          }
        }
      }
    }
  }

  #service {
    background-color: $secondry-background-color;

    .container {
      padding: cal(90) 0 cal(120);
    }

    .service {
      &__sub {
        margin-bottom: cal(19.8);
      }

      &__txt {
        font-size: cal(13);
        line-height: (30 / 13);
        letter-spacing: 0.075em;
        text-align: left;
      }

      &__list {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
        width: 100%;
        padding: 0 cal(40);
        margin: cal(59) auto 0;

        &__item {
          width: calc(50% - #{cal(17)});

          &__img {
            width: 100%;
            margin-bottom: cal(14.3);
          }

          &__title {
            font-size: cal(13);
            letter-spacing: 0.12em;
            color: #0D1824;
            text-align: center;
            white-space: nowrap;
          }

          &:nth-child(odd) {
            margin-right: cal(34);
          }

          &:nth-child(n+3) {
            margin-top: cal(60);
          }
        }
      }
    }
  }

  #gallery {
    background-color: $color-white;

    &::before {
      background-color: $color-white;
    }

    .container {
      padding: cal(70) 0 cal(84);
    }

    .gallery {
      &__slider {
        position: relative;
        // padding-bottom: cal(50);
        margin-top: cal(60);

        &__item {
          width: cal(209.6);
          margin-right: cal(10);
        }

        &__arrows {
          width: cal(126);
          height: cal(14);
          position: absolute;
          bottom: 0;
          cursor: pointer;

          &::after,
          &::before {
            content: "";
            display: inline-block;
            height: cal(2);
            background-color: $gallery-arrow-color;
            position: absolute;
          }

          &::before {
            width: calc(100% - #{cal(68)});
            top: 50%;
          }

          &::after {
            width: cal(18);
            top: 13%;
          }

          img {
            width: auto;
            height: 100%;
          }

          &.prev {
            right: 55.2%;

            &::before {
              left: 0;
            }

            &::after {
              left: cal(-2);
              transform: rotate(-45deg);
            }

            img {
              margin-left: auto;
              margin-right: 0;
            }
          }

          &.next {
            left: 55.2%;

            &::before {
              right: 0;
            }

            &::after {
              right: cal(-3);
              transform: rotate(45deg);
            }

            img {
              margin-left: 0;
              margin-right: auto;
            }
          }
        }
      }
    }
  }

  #profile {
    background-color: $third-background-color;

    &::before {
      background-color: $third-background-color;
    }

    .container {
      padding: cal(56.25) 0 cal(50);
    }

    .profile {
      &__title {
        height: cal(58);
        margin-bottom: cal(86);

        &::after {
          background-color: $color-white;
        }
      }

      &__info {
        width: 100%;
        padding: 0 cal(40);
        margin: 0 auto cal(42.5);

        &__txt {
          font-size: cal(13);
          line-height: (30 / 13);
          letter-spacing: 0.1em;
          color: $color-white;
          text-align: left;

          li {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;

            span {
              width: cal(88);
            }

            p {
              flex: 1;
            }
          }
        }
      }

      &__map {
        width: cal(295);
        height: cal(295);
        margin: 0 auto;
        position: relative;

        iframe {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }
  }

  #contact {
    background-color: $secondry-background-color;

    .container {
      padding: cal(82.5) 0 0;
    }

    .contact {
      &__sub {
        margin-bottom: cal(19.8);
      }

      &__txt {
        margin-bottom: cal(48.6);
      }
    }

    .row {
      width: cal(295);
      margin: 0 auto;
      padding-bottom: cal(108.6);

      .label-item {
        display: inline-block;

        .required {
          span {
            position: relative;
            display: inline-block;
            font-size: cal(13);
            line-height: (32 / 13);
            letter-spacing: 0.1em;
            color: $text-color;
            padding-left: cal(66);
            margin-bottom: cal(8.6);

            &::before {
              content: "必須";
              display: block;
              width: cal(56);
              height: cal(23);
              font-size: cal(13);
              line-height: cal(23);
              letter-spacing: 0.1em;
              color: $color-white;
              font-family: $tertiary-font;
              text-align: center;
              background-color: $third-background-color;
              position: absolute;
              top: 50%;
              left: 0;
              transform: translateY(-50%);
            }
          }
        }

        .not_required {
          span {
            position: relative;
            display: block;
            font-size: cal(14);
            line-height: (32 / 14);
            letter-spacing: 0.1em;
            color: $text-color;
          }
        }

        .wpcf7-form-control-wrap {
          display: inline-block;
        }

        input,
        textarea {
          font-size: cal(14);
          line-height: (32 / 14);
          letter-spacing: 0.1em;
          font-family: $tertiary-font;
          padding: 0 cal(13);

          &::-webkit-input-placeholder {
            color: #9A9898;
          }
        }

        textarea {
          resize: none;
          padding: cal(10.2) cal(13);
        }

        span.wpcf7-not-valid-tip {
          margin-top: cal(6);
        }

        .your-name-sei {
          margin-bottom: cal(8);

          input {
            width: cal(295);
            height: cal(40);
          }
        }

        .your-email,
        .your-name-mei {
          margin-bottom: cal(30);

          input {
            width: cal(295);
            height: cal(40);
          }
        }

        .tel-877 {
          margin-bottom: cal(27.3);

          input {
            width: cal(295);
            height: cal(40);
          }
        }

        .your-message {
          margin-bottom: cal(60);

          textarea {
            width: cal(295);
            height: cal(295);
          }
        }
      }

      .submit_contact {
        margin-left: auto;
        width: cal(295);
        margin-bottom: cal(57.4);

        div.wpcf7-acceptance-missing,
        div.wpcf7-validation-errors {
          padding: cal(20);
        }
      }

      .address-item {
        width: cal(295);

        .address-txt {
          font-size: cal(13);
          line-height: (30 / 13);
          letter-spacing: 0.1em;
          color: $text-color;
          margin-bottom: cal(16);
        }

        .address-content {
          font-size: cal(13);
          line-height: (30 / 13);
          letter-spacing: 0.1em;
          color: $text-color;
        }
      }
    }
  }
}
