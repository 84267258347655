footer {
  background-color: $third-background-color;

  .footer {
    color: $color-white;
    width: 100%;
    max-width: cal($spsize);
    margin: 0 auto;
    padding: cal(122) cal(40) cal(20);
    position: relative;

    &__pagetop {
      width: cal(60);
      height: cal(60);
      position: absolute;
      top: 0;
      right: cal(20);

      img {
        width: 100%;
        height: 100%;
      }
    }

    &__txt {
      font-size: cal(14);
      line-height: (22 / 14);
      letter-spacing: 0.1em;
      margin-bottom: cal(16.4);
    }

    &__list {
      font-size: cal(12);
      line-height: (22 / 12);
      letter-spacing: 0.1em;
      margin-bottom: cal(44.7);
    }

    &__logo {
      width: cal(295);
      margin-bottom: cal(18.1);
    }

    &__copyright {
      width: cal(145);
      margin-right: auto;
    }
  }
}
