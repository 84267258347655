footer {
  background-color: $third-background-color;
  position: relative;

  .footer {
    color: $color-white;
    width: 100%;
    max-width: cal($pcsize, pc);
    margin: 0 auto;
    padding: cal(138, pc) cal(65, pc) cal(27, pc);
    position: relative;

    &__pagetop {
      width: cal(83.23, pc);
      position: absolute;
      right: cal(66, pc);
      top: 0;

      img {
        width: 100%;
      }
    }

    &__txt {
      margin-bottom: cal(13.4, pc);
      font-size: cal(18, pc);
      line-height: (26 / 18);
      letter-spacing: 0.1em;
    }

    &__list {
      font-size: cal(14, pc);
      line-height: (25 / 14);
      margin-bottom: cal(66.5, pc);
    }

    &__logo {
      width: cal(692, pc);
      margin-bottom: cal(36.4, pc);
    }

    &__copyright {
      width: cal(183, pc);
      margin-right: auto;
    }
  }
}
